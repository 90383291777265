const Brand: React.FC = (): JSX.Element => (
  <svg
    width="202"
    height="16"
    viewBox="0 0 202 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="brand-svg"
  >
    <g clipPath="url(#clip0_106_8575)">
      <path d="M19.3027 15.7297V0.292969H20.6756V15.7297H19.3027Z" fill="currentColor" />
      <path
        d="M34.5233 16.0002C31.9566 16.0002 30.0325 15.34 28.7512 14.0196C27.4698 12.6993
      26.8285 10.6965 26.8271 8.01131C26.8271 6.27099 27.1199 4.80794 27.7054 3.62216C28.2638
      2.46422 29.1855 1.52056 30.33 0.934967C31.4862 0.327943 32.9196 0.0237579 34.6303 0.0224119C35.6052
      0.00972744 36.5775 0.127189 37.5214 0.371686C38.3029 0.573652 39.037 0.927652 39.6817 1.41345C40.2699
      1.86613 40.7373 2.45697 41.0424 3.13358C41.3716 3.89798 41.5328 4.7242 41.5148 5.55629H39.9401C39.9621
      4.87752 39.8238 4.20311 39.5363 3.58784C39.2818 3.07143 38.8987 2.62923 38.4239 2.3038C37.9127 1.9698 37.3426
      1.73616 36.7441 1.61535C36.0526 1.46834 35.3473 1.39659 34.6404 1.40134C33.7758 1.3903 32.9144 1.50802 32.0844
      1.75061C31.3561 1.96667 30.6891 2.35202 30.1382 2.87516C29.5739 3.43139 29.1497 4.11365 28.9006 4.86582C28.5941
      5.80793 28.4474 6.79467 28.4665 7.78519V8.25964C28.4665 9.78998 28.7061 11.0235 29.1853 11.9603C29.6201 12.8538
      30.3464 13.5723 31.2446 13.9974C32.2464 14.4397 33.3337 14.6548 34.4284 14.6273C35.7744 14.6273 36.8511 14.4436
      37.6587 14.0762C38.4275 13.7404 39.0476 13.1361 39.403 12.3762C39.7624 11.6104 39.9421 10.6433 39.9421
      9.47503V9.08538H34.1195V7.74077H41.5229V15.7297H40.2631L40.0612 13.6138C39.7018 14.1827 39.2117 14.6575
      38.6318 14.9988C38.028 15.356 37.371 15.6143 36.6856 15.764C35.9757 15.9221 35.2506 16.0013 34.5233 16.0002Z"
        fill="currentColor"
      />
      <path d="M48.9209 15.7297V0.292969H50.7379V15.7297H48.9209Z" fill="currentColor" />
      <path
        d="M61.9815 15.7297V2.09385H56.6475V0.292969H69.4071V2.09385H64.0509V15.7297H61.9815Z"
        fill="currentColor"
      />
      <path
        d="M72.2598 15.7297L78.3347 0.292969H81.351L87.4482 15.7297H84.9285L83.489 12.0391H76.0634L74.6502
      15.7297H72.2598ZM76.8286 10.0585H82.7239L80.923 5.35644C80.8624 5.22117 80.7918 5.03745 80.709 4.80527C80.6262
      4.57309 80.5333 4.30861 80.4283 4.01587C80.3234 3.72313 80.2264 3.42836 80.1235 3.12754C80.0205 2.82672 79.9337
      2.55013 79.8429 2.29574H79.7076L79.3442 3.33145L78.9505 4.46609C78.8233 4.83556 78.7143 5.13032 78.6234
      5.35644L76.8286 10.0585Z"
        fill="currentColor"
      />
      <path d="M92.8281 15.7297V0.292969H95.4164V13.4786H103.135V15.7297H92.8281Z" fill="currentColor" />
      <path
        d="M108.717
      15.7297V0.292969H111.598V6.59404H118.866V0.292969H121.741V15.7297H118.866V9.06723H111.598V15.7297H108.717Z"
        fill="currentColor"
      />
      <path
        d="M135.47 16.0003C133.855 16.0003 132.455 15.7115 131.272 15.1341C130.106 14.5657 129.155 13.6331 128.565
      12.4772C127.935 11.2861 127.62 9.79744 127.62 8.01136C127.62 6.19701 127.935 4.70032 128.565 3.52126C129.164
      2.37799 130.114 1.45775 131.276 0.896657C132.457 0.313859 133.855 0.0224609 135.47 0.0224609C137.12 0.0224609
      138.533 0.311168 139.709 0.888581C140.867 1.45308 141.813 2.3727 142.411 3.51319C143.033 4.69762 143.344 6.19701
      143.344 8.01136C143.344 9.79609 143.029 11.2847 142.399 12.4772C141.813 13.6321 140.87 14.5668 139.709
      15.1422C138.534 15.7142 137.121 16.0003 135.47 16.0003ZM135.47 13.3756C136.144 13.3884 136.814 13.2776 137.448
      13.0486C138.004 12.8441 138.502 12.5098 138.902 12.0734C139.307 11.6182 139.61 11.0812 139.79 10.4987C140.004
      9.79488 140.107 9.0619 140.095 8.32631V7.72063C140.107 6.98177 140.004 6.24556 139.79 5.53817C139.612 4.95118
      139.309 4.40977 138.902 3.95129C138.505 3.51951 138.011 3.18808 137.46 2.98423C136.177 2.54811 134.785 2.54811
      133.501 2.98423C132.954 3.1862 132.463 3.51805 132.072 3.95129C131.672 4.41128 131.376 4.95273 131.206
      5.53817C130.999 6.24689 130.901 6.98259 130.913 7.72063V8.32631C130.898 9.06066 130.994 9.7931 131.198
      10.4987C131.37 11.0797 131.665 11.6168 132.064 12.0734C132.455 12.5067 132.945 12.8385 133.493 13.0405C134.127
      13.2692 134.796 13.3801 135.47 13.3676V13.3756Z"
        fill="currentColor"
      />
      <path
        d="M156.041 16C154.601 16 153.366 15.7745 152.338 15.3236C151.352 14.9108 150.525 14.1934 149.976
      13.2764C149.428 12.3612 149.154 11.2212 149.154 9.85638V0.270508H152.71V9.75947C152.71 10.8255 152.995
      11.6546 153.566 12.2468C154.136 12.839 154.954 13.1351 156.019 13.1351C157.095 13.1351 157.924 12.839
      158.504 12.2468C159.084 11.6546 159.373 10.8255 159.372 9.75947V0.270508H162.95V9.85638C162.95 11.2212
      162.672 12.3612 162.116 13.2764C161.56 14.1924 160.729 14.909 159.742 15.3236C158.715 15.7732 157.481
      15.9986 156.041 16Z"
        fill="currentColor"
      />
      <path
        d="M175.82 16.0002C174.899 16.0069 173.979 15.9224 173.074 15.7478C172.278 15.5966 171.514 15.3112 170.813
      14.9039C170.176 14.5299 169.646 13.9988 169.273 13.3615C168.877 12.6424 168.682 11.8298 168.71
      11.0094V10.8075C168.708 10.7469 168.715 10.6863 168.732 10.6278H172.618C172.622 10.6843 172.622 10.7409 172.618
      10.7974C172.611 10.853 172.607 10.909 172.606 10.965C172.588 11.3795 172.718 11.7869 172.973 12.1138C173.253
      12.4278 173.622 12.649 174.031 12.7477C174.545 12.8869 175.074 12.9549 175.606 12.9496C175.914 12.951 176.222
      12.9361 176.529 12.9052C176.789 12.88 177.045 12.8272 177.294 12.7477C177.504 12.6797 177.704 12.5847 177.89
      12.4651C178.05 12.3665 178.182 12.2272 178.271 12.0613C178.357 11.8906 178.4 11.7013 178.396 11.5101C178.398
      11.3461 178.363 11.1838 178.293 11.0355C178.223 10.8872 178.12 10.7568 177.993 10.6541C177.678 10.402 177.319
      10.2109 176.935 10.0908C176.493 9.9414 175.994 9.7994 175.439 9.66481L173.704 9.23679C173.114 9.0905 172.535
      8.90226 171.972 8.67351C171.436 8.4603 170.932 8.17282 170.476 7.81951C170.032 7.47186 169.671 7.02982 169.418
      6.52537C169.14 5.93795 169.006 5.29321 169.024 4.64373C169.005 3.90886 169.184 3.18232 169.541 2.54001C169.89
      1.94486 170.381 1.44595 170.971 1.0884C171.62 0.696952 172.332 0.419071 173.074 0.266699C173.911 0.0878306
      174.763 -0.00150576 175.618 0.000200526C176.454 -0.00486093 177.288 0.0859101 178.104 0.270737C178.833 0.432118
      179.53 0.717568 180.163 1.11465C180.743 1.47322 181.228 1.96716 181.576 2.55414C181.93 3.19328 182.113 3.91309
      182.107 4.64373V4.90619H178.229V4.74872C178.234 4.42217 178.135 4.10239 177.946 3.83616C177.729 3.55134 177.437
      3.33309 177.102 3.20626C176.657 3.03621 176.183 2.95593 175.707 2.97004C175.231 2.95968 174.756 3.01264 174.294
      3.12752C173.972 3.20418 173.674 3.35879 173.426 3.57774C173.329 3.66657 173.252 3.77462 173.2 3.89494C173.148
      4.01525 173.122 4.14516 173.123 4.27629C173.121 4.43325 173.157 4.58832 173.228 4.72872C173.298 4.86911 173.4
      4.99084 173.527 5.08386C173.848 5.32468 174.21 5.50714 174.595 5.62291C175.046 5.77501 175.548 5.91431 176.103
      6.04083C176.657 6.16735 177.231 6.30195 177.825 6.44462C178.41 6.58557 178.985 6.76635 179.545 6.98569C180.083
      7.1938 180.59 7.4736 181.053 7.81749C181.493 8.15141 181.853 8.57898 182.107 9.06922C182.388 9.62718 182.526
      10.2457 182.511 10.8701C182.511 12.099 182.222 13.0889 181.643 13.8399C181.031 14.6144 180.2 15.1865 179.258
      15.4813C178.149 15.8423 176.987 16.0176 175.82 16.0002Z"
        fill="currentColor"
      />
      <path
        d="M188.287
      15.7292V0.248047H201.489V3.80338H192.967V6.16552H200.599V9.58559H192.967V12.1739H201.648V15.7292H188.287Z"
        fill="currentColor"
      />
      <path
        d="M7.08038 15.7115H0V0.294922H7.08038C8.69963 0.296525 10.2521 0.940481 11.3971 2.08547C12.5421 3.23045 13.186
      4.78293 13.1876 6.40218V9.6042C13.186 11.2234 12.5421 12.7759 11.3971 13.9209C10.2521 15.0659 8.69963 15.7099
      7.08038 15.7115ZM1.22549 14.488H7.08038C8.37515 14.4864 9.61643 13.9713 10.532 13.0558C11.4475 12.1402 11.9626
      10.899 11.9642 9.6042V6.40218C11.9626 5.10741 11.4475 3.86613 10.532 2.95059C9.61643 2.03505 8.37515 1.51999
      7.08038 1.51839H1.22549V14.488Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_106_8575">
        <rect width="201.655" height="16" fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
);

export default Brand;
