/* eslint-disable max-len */

import type { NextPage } from 'next';
import LinkWithQuery from 'components/LinkWithQuery';
import { useIntl } from 'react-intl';
import { Brand, BrandSmall } from 'components/icons';
import CurrencyMenu from 'components/CurrencyMenu/CurrencyMenu';
import Lottie from 'lottie-react';
import notFoundLight from 'public/assets/lottie/notFoundLight.json';
import notFoundDark from 'public/assets/lottie/notFoundDark.json';
import classNames from 'classnames';
import Background from 'components/Background/Background';
import { useEffect, useState } from 'react';

const NotFound: NextPage = () => {
  const intl = useIntl();
  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    if (typeof document !== 'undefined') {
      if (document.body.classList.contains('dark')) {
        setIsDarkMode(true);
      }
    }
  }, []);

  return (
    <div className={classNames('h-screen w-screen relative dark:bg-black-1 bg-white')}>
      <Background pattern="pattern1">
        <div
          className={classNames(
            'px-10 py-3 flex justify-between items-center dark:bg-black-1 dark:text-white bg-white-5 text-black-1 border-b border-gray-9',
          )}
        >
          <LinkWithQuery href="/">
            <div className={classNames('hidden sm:block py-[0.813rem]')}>
              <Brand />
            </div>
            <div className={classNames('sm:hidden py-[0.813rem]')}>
              <BrandSmall />
            </div>
          </LinkWithQuery>
          <div className={classNames('mt-2 flex items-center')}>
            <CurrencyMenu />
          </div>
        </div>
        <div className={classNames('mx-auto min-w-fit max-w-[1286px]')}>
          <main className={classNames('mx-10 mt-6 py-[7.313rem] rounded-2xl  dark:bg-black-3 flex flex-col gap-y-6 items-center')}>
            <h1 className={classNames('text-2xl ssm:text-4xl md:text-6.5xl px-4 sm:px-0 leading-none font-semibold dark:text-gray-10 text-black-4')}>
              {intl.formatMessage({ defaultMessage: 'Página no encontrada' })}
            </h1>
            <div className={classNames('w-[152px] h-[152px]')}>
              <Lottie animationData={isDarkMode ? notFoundDark : notFoundLight} loop />
            </div>
            <div className={classNames('text-sm sm:text-base text-center sm:text-left px-4 sm:px-0 font-archivo dark:text-gray-10 text-black-4')}>
              <p>
                {intl.formatMessage({ defaultMessage: 'Parece que has llegado a una página que no existe.' })}
              </p>
              <p>
                {intl.formatMessage({ defaultMessage: 'No te preocupes, puedes volver a la página de inicio.' })}
              </p>
            </div>
            <div>
              <LinkWithQuery href="/">
                <button
                  type="button"
                  className={classNames('text-xs sm:text-sm py-2 px-4 bg-black text-white dark:bg-white dark:text-black rounded-md font-archivo')}
                >
                  {intl.formatMessage({ defaultMessage: 'Volver al inicio' })}
                </button>
              </LinkWithQuery>
            </div>
          </main>
        </div>
      </Background>
    </div>
  );
};

export default NotFound;
