const BrandSmall: React.FC = (): JSX.Element => (
  <svg width="48" height="22" viewBox="0 0 48 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_242_8567)">
      <path
        d="M12.4314 21.1695H0V0.851562H12.4314C16.8694 0.851562 20.4807 4.46282 20.4807 8.90088V13.1229C20.4807
    17.561 16.8694 21.1722 12.4314 21.1722V21.1695ZM1.6136 19.556H12.4314C15.9786 19.556 18.8671 16.6701 18.8671
    13.1202V8.89821C18.8671 5.35096 15.9813 2.46249 12.4314 2.46249H1.6136V19.556Z"
        fill="currentColor"
      />
      <path d="M48.0009 0.828125H27.6562V21.1728H48.0009V0.828125Z" fill="currentColor" />
    </g>
    <defs>
      <clipPath id="clip0_242_8567">
        <rect width="48" height="20.3447" fill="currentColor" transform="translate(0 0.828125)" />
      </clipPath>
    </defs>
  </svg>
);

export default BrandSmall;
