import { ReactNode } from 'react';
import Patterns from './patterns';
import { EBackgroundPattern } from './model/Background.model';

export interface IProps {
  children: ReactNode;
  pattern?: keyof typeof EBackgroundPattern;
}

const Background: React.FC<IProps> = ({
  pattern = EBackgroundPattern.pattern1,
  children,
}: IProps): JSX.Element => {
  const PatternComponent = Patterns[pattern];

  return (
    <>
      <section className="relative z-[1]">
        {children}
      </section>
      <div className="overflow-hidden w-full flex items-center z-0 absolute bottom-0">
        <PatternComponent className="min-w-full h-fit text-[#8A6DF1]" />
      </div>
    </>
  );
};

export default Background;
